import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { useField } from "formik";

const InputField = (props) => {
  const { helperText, ...rest } = props;
  const [field, meta] = useField(props);
  function _renderHelperText() {
    const { touched, error } = meta;
    if (touched && error) {
      return error;
    } else {
      return helperText;
    }
  }

  return (
    <TextField
      type="text"
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
  );
};

const CheckboxField = (props) => {
  // We're pulling out initial_value so it doesn't go into rest
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { helperText, label, initial_value, className = "", ...rest } = props;
  const [field, meta, helper] = useField(props);
  function _renderHelperText() {
    const { touched, error } = meta;

    if (touched && error) {
      return error;
    } else {
      return helperText;
    }
  }

  const _onChange = (e, v) => {
    helper.setTouched(true);
    helper.setValue(v);
  };

  return (
    <FormControl
      className={className}
      required
      error={(meta.touched && meta.error && true) as boolean}
      component="fieldset"
    >
      <FormGroup>
        <FormHelperText style={{ textAlign: "center", minHeight: 19 }}>
          {_renderHelperText()}
        </FormHelperText>
        <FormControlLabel
          control={
            <Checkbox checked={field.value} onChange={_onChange} {...rest} />
          }
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
};

export { InputField, CheckboxField };
