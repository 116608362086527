import { useEffect } from "react";

export default function RedirectToApp(props) {
  const { origin, href } = props.location;
  const url = href || "";
  const new_url = url.replace(origin, getAppOrigin());
  useEffect(() => {
    window.location = new_url;
  }, []);
  return <span>{/* redirecting */}</span>;
}

export function navigateToApp() {
  window.location.href = getAppOrigin();
}

export function getAppOrigin() {
  if (process.env.NODE_ENV === "development") {
    const { origin, host } = window.location;
    return `${origin.replace(host, host.replace("3001", "3000"))}/app`;
  } else {
    return `/app`;
  }
}
